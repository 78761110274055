<template>
    <!-- list-search-bar 定义了列表页的搜索栏的普遍结构样式的抽象：
        （见UI图）一些表单组件，其下方跟着 "搜索" 和 "清空" 两个按钮。
        
        默认按钮在下方，如果props.inline = true，按钮会跟随在右侧 -->
    <div class="list-search-bar" :class="className">
        <el-form class="private" :inline="true" label-suffix=":"
            :label-width="labelWidth" @submit.native.prevent>

            <!-- label-width="auto" -->
            <el-form-item v-if="showSystem" label="快速筛选"
                class="system-tab" style="margin-right: 0">
                <li class="system-tab--item"
                    v-for="sys in systemList"
                    :key="sys.id"
                    @click="handleTabClick(sys.id)"
                    :class="{active: sys.id == system}"
                >{{ sys.name }}</li>
            </el-form-item>

            <slot></slot>

            <template v-if="showButtons && inline">
                <el-button type="primary" @click.stop="handleSubmit">{{confirmText}}</el-button>
                <el-button v-if="showClear" @click.stop="handleClear">{{clearText}}</el-button>
            </template>
        </el-form>

        <div v-if="showButtons && !inline" class="mt4" :style="{marginLeft: labelWidth}">
            <el-button type="primary" @click.stop="handleSubmit">{{confirmText}}</el-button>
            <el-button v-if="showClear" @click.stop="handleClear">{{clearText}}</el-button>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ListSearchBar',

    props: {
        showSystem: {    // 是否显示系统tab
            type: Boolean,
            default: true
        },
        
        system: {        // 选中的系统
            type: Number,
            default: 0
        },

        inline: {        // slot 容器盒子是否时 flex 模型
            type: Boolean,
            default: false
        },

        offset: {         // 按钮栏左侧偏移距离，为了和表单组件对齐
            type: Number,
            default: 100
        },

        confirmText: {    // 主按钮的文字 
            type: String,
            default: "搜索"
        },

        showClear: {      // 是否显示次按钮
            type: Boolean,
            default: true
        },

        clearText: {      // 次按钮的文字
            type: String,
            default: "清空"
        },

        showButtons: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            systemList: [],
            submitProtecting: false, // 提交保护中，防止连续点击
            clearProtecting: false   // 清除保护中，防止点徐点击
        }
    },

    computed: {
        className () {
            return this.flexbox ? "flex" : ""
        },

        labelWidth () {
            return this.offset === 0 ? "auto" : this.offset + "px"
        },

        marginLeft () {
            return this.inline ? "32px" : this.labelWidth
        }
    },

    methods: {
        async fetchData () {
            try {
                const res = await this.$axios({
                    url: "/api/system/querySysAll"
                })

				if (res.code === 2000) {
					const list = res.data.systemList.map(item=>({
                        ...item,
                        owned: (this.userInfo?.empInfoList||[]).some(
                            ({system})=>system ==item.sysCode)
                    }));
                    this.$store.commit("setSystemEnum", list);
					this.initSystemList([...list]);
				} else if (res.code !== 1003)
					throw res
                
            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.initSystemList();
            }
        },

        initSystemList (arr=[]) {
            arr.unshift({ name: "全部", id: 0 })
            this.systemList = arr;
        },

        // 点击快速筛选
        handleTabClick (sys) {
            if (sys != this.system) {
                // console.log(sys)
                this.$emit("update:system", sys);
                this.$emit("systemchange", sys)
            }
        },

        // 列表搜索栏的表单提交事件
        handleSubmit (e) {
            if (this.submitProtecting) return;
            this.submitProtecting = true;
            setTimeout(() => {
                this.submitProtecting = false;
            }, 2e3);

            // console.log("列表搜索栏激活了提交事件", e);
            this.$emit("search", e); // 发送 "search" 事件
        },

        // 列表搜索栏的清空事件
        handleClear (e) {
            if (this.clearProtecting) return;
            this.clearProtecting = true;
            setTimeout(() => {
                this.clearProtecting = false;
            }, 2e3);

            // console.log("列表搜索栏激活了清空事件", e);
            this.$emit("clear", e); // 发送 "clear" 事件
        }
    },

    created () {
        if (this.showSystem) this.fetchData()
    }
}
</script>

<style lang='scss' scoped>

    .list-search-bar {
        padding: 16px;
        background: #F4F7FA;

        // 当它不是第一个元素时（上方有其他元素），设置上外边距
        &:nth-child(n+2) {
            margin-top: 16px;
        }
        
        .el-form {
            margin-right: -32px; // 抵消内部元素的margin-right

            // 内部元素浮动时，自动拓展其自身大小
            &::after{
                content: "";
                display: table;
                clear: both;
            }

            // &.flex {
            //     display: flex;
            // }

            /deep/ .el-form-item {
                margin-right: 32px;
            }
        }

        .system-tab {
            width: 100%;
        }

        .system-tab--item {
            color: #666666;
            cursor: pointer;

            &:nth-child(n+2) {
                margin-left: 48px;
            }

            &.active {
                color: #303133;
                border-bottom: 2px solid #2878FF;
            }
        }

        // .system-tab--list {
        //     display: flex;
        //     outline: 1px solid yellow;
        // }

        // > div {
        //     margin-top: 16px;
        // }

        .el-button + .el-button {
            margin-left: 16px;
        }
    }

</style>